<script>
import Vue from 'vue'
import { LINE_PATH_CONFIG } from '../../constants/mapSettings'

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      default() {
        return Object
      },
    },
    path: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      poly: null,
    }
  },

  mounted() {
    Vue.prototype.$globalGoogleMap = this.map
    const { Polyline } = this.google.maps
    this.poly = new Polyline({
      path: this.path,
      map: this.map,
      ...LINE_PATH_CONFIG,
    })
  },
  render() { return null },
}
</script>
