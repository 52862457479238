import colorPalette from './colorPalette'
import mapStyles from './mapStyles'

const {
  COLOR_LANDSCAPE,
  COLOR_BORDERS,
  COLOR_WATER,
  COLOR_LINE,
  COLOR_POINT_FILL,
  COLOR_SELECTED_POINT,
  COLOR_LINE_STROKE,
} = colorPalette

const COLORS = {
  BORDERS: COLOR_BORDERS,
  LANDSCAPE: COLOR_LANDSCAPE,
  LINE: COLOR_LINE,
  POINT: COLOR_SELECTED_POINT,
  POINT_FILL: COLOR_POINT_FILL,
  WATER: COLOR_WATER,
  STROKE_COLOR: COLOR_LINE_STROKE,
}

const POINT_MARKER_ICON_CONFIG = {
  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 0.7,
  strokeWeight: 4,
  strokeColor: COLORS.POINT,
  fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1,
}

const LINE_SYMBOL_CONFIG = {
  path: 'M 0,-2 0,2',
  strokeOpacity: 1,
  strokeWeight: 5,
  strokeColor: COLORS.STROKE_COLOR,
  scale: 1,
}

const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: false,
  strokeOpacity: 1,
  strokeWeight: 10,
  strokeColor: COLORS.LINE,
  icons: [
    {
      icon: LINE_SYMBOL_CONFIG,
      repeat: '5px',
    },
  ],
}

const mapSettings = {
  clickableIcons: false,
  streetViewControl: true,
  panControlOptions: true,
  gestureHandling: 'cooperative',
  mapTypeControl: true,
  zoomControlOptions: {
    style: 'SMALL',
  },
  zoom: 14,
  minZoom: 2,
  maxZoom: 20,
  styles: mapStyles,
  mapTypeId: 'terrain',
}

export { mapSettings, LINE_PATH_CONFIG, POINT_MARKER_ICON_CONFIG }
