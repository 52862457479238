<script>
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
    markers: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    lines: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    lastLocation: {
      type: Boolean,
      default() {
        return false
      },
    },
    bluetoothLocation: {
      type: Boolean,
      default() {
        return false
      },
    },
  },

  mounted() {
    const { Marker } = this.$googleMap.maps

    const markerIcon = this.marker.icon ? require(`@/assets/images/icons/ride/${this.marker.icon}`) : null
    const marker = new Marker({
      position: { lat: this.marker.position.lat, lng: this.marker.position.lng },
      marker: this.marker,
      map: this.map,
      icon: {
        url: markerIcon,
        size: new this.$googleMap.maps.Size(30, 30),
        scaledSize: new this.$googleMap.maps.Size(25, 25),
        anchor: new this.$googleMap.maps.Point(10, 10),
      },
    })

    
    if (this.lines.length > 0) {
      const bounds = new this.$googleMap.maps.LatLngBounds()
      this.lines.forEach(line => {
        bounds.extend(line)
      })
      this.map.fitBounds(bounds)
    }



    if (this.lastLocation || this.bluetoothLocation) {
      try {
        const geocoder = new this.$googleMap.maps.Geocoder()
        const latLng = new this.$googleMap.maps.LatLng({ lat: marker.marker.position.lat, lng: marker.marker.position.lng })
        geocoder.geocode({
          latLng,
        }, async (results, status) => {
          if (status === this.$googleMap.maps.GeocoderStatus.OK) {

            const content = (`${marker.marker.position.info} ${results[0].formatted_address}`)

            const infowindow = new this.$googleMap.maps.InfoWindow({
              title: marker.position.info,
              content,
            })
            this.$googleMap.maps.event.addListener(marker, 'click', () => {
              infowindow.open(this.map, marker)
            })

            const locationBounds = new this.$googleMap.maps.LatLngBounds()

            this.markers.forEach(locationMarker => {
              locationBounds.extend({ lat: locationMarker.position.lat, lng: locationMarker.position.lng })
            })
            this.map.fitBounds(locationBounds)
          }
        })
      } catch (error) {
        console.log(`Error getting LatLon coords: ${error}`)
      }
    }
  },
  render() { return null },
}
</script>
