<template>
  <GoogleMapLoader
    :map-config="mapConfig"
    api-key="AIzaSyCnpNoSCz5YBcJem2z4ihqYBHxv4m_zQS8"
  >
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
        :markers="markers"
        :lines="lines[0] ? lines[0].path : []"
        :last-location="lastLocation"
        :bluetooth-location="bluetoothLocation"
      />
      <div v-if="pathAllowed">
        <GoogleMapLine
          v-for="line in lines"
          :key="line.id"
          :path.sync="line.path"
          :google="google"
          :map="map"
        />
      </div>
    </template>
  </GoogleMapLoader>
</template>
<script>
import { mapSettings } from '@/views/rides/constants/mapSettings'
import GoogleMapLoader from '@/views/rides/components/Map/GoogleMapLoader.vue'
import GoogleMapMarker from '@/views/rides/components/Map/GoogleMapMarker.vue'
import GoogleMapLine from '@/views/rides/components/Map/GoogleMapLine.vue'

export default {
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapLine,
  },
  props: {
    markers: {
      type: Array,
      default() {
        return true
      },
    },
    lines: {
      type: Array,
      default() {
        return []
      },
    },
    pathAllowed: {
      type: Boolean,
      default() {
        return true
      },
    },
    lastLocation: {
      type: Boolean,
      default() {
        return false
      },
    },
    bluetoothLocation: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  computed: {
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
      }
    },
    mapCenter() {
      return { lat: this.markers[0].position.lat, lng: this.markers[0].position.lng }
    },
  },
}
</script>
<style lang="scss">
/*  map  */
.travel-map {
    height: 400px !important;
    width: 100% !important;
}
</style>
