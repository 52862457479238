<template>
  <div>
    <div
      id="google-map-container"
      ref="googleMap"
      class="google-map"
    />
    <template v-if="$googleLoaded && Boolean($googleMap) && Boolean(map)">
      <slot
        :google="$googleMap"
        :map="map"
      />
    </template>
  </div>
</template>

<script>

export default {
  props: {
    mapConfig: {
      type: Object,
      default() {
        return null
      },
    },
    apiKey: {
      type: String,
      default() {
        return null
      },
    },
  },

  data() {
    return {
      google: null,
      map: null,
      fakeMap: null,
    }
  },

  mounted() {
    this.initializeMap()
  },

  methods: {
    initializeMap() {
          const mapContainer = this.$refs.googleMap
          this.map = new this.$googleMap.maps.Map(mapContainer, this.mapConfig)
          this.fakeMap = new this.$googleMap.maps.Marker({
            map: this.map,
          })
    },
  },
  render() { return null },
}
</script>

<style scoped>
.google-map {
  width: 100%;
  min-height: 100%;
}
</style>
